.div-girl {
  width: 15vw;
  height: 100%;
  position: relative;
  // right: 150px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../Assets/Images/girl.svg");
}
