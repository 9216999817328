.div-btn {
  border-radius: 12px;
  // border: 2px solid #b59494;
  // background: rgba(255, 255, 255, 0.1);
  background: #000;
  width: 310px;
  height: 50px;
  text-align: end;
  position: relative;
  left: -250px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.div-info:hover {
  position: relative;
  left: -190px;
  transition: 0.3s;
}
.div-stats:hover {
  position: relative;
  left: -190px;
  transition: 0.3s;
}
.div-btn.snap:hover {
  position: relative;
  left: -100px;
  transition: 0.3s;
}
.lable {
  color: #ffffff;
  font-family:  "Lilita One", "cursive";//regular
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 16px;
}
.icon {
  margin-right: 16px;
  width: 32px;
}

.mobile-btn{
    z-index: 4;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -55vh;
    width: 50vw;
    height: 99.6vh;
    display: contents;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 30px rgb(0, 0, 0);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    visibility: hidden;
    padding: 0px;
    .btn-area .div-btn{
      width: 80px !important;
    }
}

.mobile-btn-active{
  transition: 0.5s;
  visibility: visible !important;
  left: 0;
}

.desktop-btn{
  flex-direction: column;
  z-index: 2;
}


.side-canvas{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px;
  visibility: hidden;
  transition: 0.4s;
  
  .side-canvas-header{
    padding: 10px 0px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
  .side-canvas-container{
    height: 100%;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .opt-btns-area{
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  
}

@media screen and (max-width: 480px) {
  .div-btn {
    width: auto; /* Full width for mobile */
    left: 0;
    justify-content: center;
  }

  .div-btn.snap {
    left: -100px; /* Adjust this as needed for the snap effect */
  }

  .lable {
    margin-right: 0; /* Remove margin for mobile */
  }

  .icon {
    margin-right: 0; /* Remove margin for mobile */
  }
}
@media screen and (max-width: 992px){
 .desktop-btn{
  display: none !important;
 }
}
@media screen and (min-width: 992px){
  .mobile-btn{
   display: none !important;
  }
  body{
    overflow: hidden;
  }
 }