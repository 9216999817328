.background {
  width: 100vw;
  height: 100vh;
  background: #ffffff70;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .modal-wrapper {
    // color: #141414;
    // border-radius: 20px;
    // background: linear-gradient(226deg, #000 0%, #101010 100%);
    // box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px !important;
    background: -webkit-radial-gradient(38% -25%, rgba(252, 73, 126, 1) 0, rgba(252, 73, 126, 0.6) 0, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.4) 100%), -webkit-radial-gradient(62% -25%, rgba(127, 48, 255, 0.6) 0, rgba(141, 50, 242, 0) 40%, rgba(141, 50, 242, 0) 100%), -webkit-linear-gradient(-90deg, rgba(237, 56, 56, 1) 0, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 100%) !important;
    background: -moz-radial-gradient(38% -25%, rgba(252, 73, 126, 1) 0, rgba(252, 73, 126, 0.6) 0, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.4) 100%), -moz-radial-gradient(62% -25%, rgba(127, 48, 255, 0.6) 0, rgba(141, 50, 242, 0) 40%, rgba(141, 50, 242, 0) 100%), -moz-linear-gradient(180deg, rgba(237, 56, 56, 1) 0, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 100%) !important;
    background: radial-gradient(at 38% -25%, rgba(252, 73, 126, 1) 0, rgba(252, 73, 126, 0.6) 0, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.4) 100%), radial-gradient(at 62% -25%, rgba(127, 48, 255, 0.6) 0, rgba(141, 50, 242, 0) 40%, rgba(141, 50, 242, 0) 100%), linear-gradient(180deg, rgba(237, 56, 56, 1) 0, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 100%) !important;
    border: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #7f30ff, #ff4880);

    .modal-header {
      display: flex;
      justify-content: center;
      align-items: center;

      .h1 {
        // color: #fff;
        font-family: "Luckiest Guy", "cursive"; //header
        text-align: center;
        line-height: normal;
        background: -webkit-linear-gradient(#7f30ff 0%, #ff4880 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .close {
        position: relative;
        right: 0;
      }

      .modalClose-share {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 61px;
        height: 60px;
        padding: 0;
        z-index: 10;
        cursor: pointer;
        border: 0;
        background-image: url("../../Assets/Images/modelCloseBtn.svg");
        background-repeat: no-repeat;
      }
    }

    .modal-container {
      max-width: 80vw;
      max-height: 80vh;
      padding: 20px;
      width: 500px;
      height: fit-content;
      display: flex;
      flex-direction: column;

      .modal-top-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .div-switch {
          display: flex;
          margin-bottom: 16px;
          align-items: center;

          .text-switch {
            color: #fff;
            font-family: "Lilita One", "cursive"; //regular
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            margin-right: 16px;
          }

          .toggle-switch {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 25px;
          }

          .toggle-switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.288);
            transition: 0.4s;
            border-radius: 34px;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2px;
            background-color: #fff;
            transition: 0.4s;
            border-radius: 50%;
          }

          input:checked+.slider {
            background: linear-gradient(238deg, #7f30ff 0%, #ff4880 100%);
          }

          input:checked+.slider:before {
            transform: translateX(26px);
          }

          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        }
      }

      .modal-mid-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .textarea {
          margin-bottom: 16px;
          padding: 15px 20px;
          border: none;
          outline: none;
          font-size: 14px;
          padding: 10px;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.1);
          color: #fff;
          font-family: "Lilita One", "cursive"; //regular
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          min-height: 250px;
          max-height: 400px;
        }
      }

    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 0px 20px 0px;

      .button {
        padding: 10px 20px;
        color: #fff;
        font-family: "Luckiest Guy", "cursive"; //header
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-variant: small-caps;
        cursor: pointer;
      }

      .primary-btn {
        border: none;
        border-radius: 12px;
        background: linear-gradient(238deg, #7f30ff 0%, #ff4880 100%);
      }

      .button.primary-btn:hover {
        background: linear-gradient(238deg, #7f30ff87 0%, #ff488094 100%);
      }

      .button.primary-btn:active {
        background: linear-gradient(238deg, #7f30ff87 0%, #ff488094 100%);
        margin-top: 5px;
      }
    }
  }

}


@media(max-width: 460px) {
  .modal-wrapper {
    .modal-header {
      .h1 {
        font-size: 22px;
        width: 50vw;
      }
    }
  }
}