.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 10vh;
  margin-bottom: 17px;
  z-index: 1;

  .logo {
    width: 200px;
    // margin-top: 5px;
    position: relative;
    top: 30px;
  }

  .navigation {
    display: flex;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 35px;
  
    .nav-icon {
      height: 70px;
      cursor: pointer;
      padding: 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 3rem;
    margin-bottom: 50px;
    z-index: 1;

    .logo{
      top: 35px !important;
    }
    .navigation {
      display: flex;
      align-items: center;
      position: absolute;
      left: 10px;
      top: 15px;
    
      .nav-icon {
        height: 70px;
      }
    }
  }
}
@media screen and (min-width: 992px){
  .navigation{
   display: none !important;
   
  }
}
@media screen and (max-width: 992px){
  .header .logo {
    width: 26vw;
    margin-top: 5px;
    min-width: 160px;
    position: relative;
    top: 20px;
 }
}