.container {
  width: 100vw;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side-col {
  width: 25vw;
  height: 100%;
}

.left-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .inner-side-col {
    height: 100%;
  }
}

.left-container .btn-area .div-btn+.div-btn {
  margin-top: 20px;
}

.gusses-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  position: relative;
  right: 0px;
}

.center-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 30px;
  gap: 10px;
}

.center-container {
  width: 100%;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;
  margin: 5px 0px;
  z-index: 1;
}

.inner-container {
  width: 45vw;
}

//old
.hint-list {
  display: flex;
  flex-direction: column;

  .hint-text {
    display: inline-block;
    text-align: left;
    margin-bottom: 10px;
    color: #ffffff;
    font-family: "Luckiest Guy", "cursive"; //header
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}

//new-hint
.hint-container {
  background-color: #bfa38b;
  border: 3px solid #436a4a;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .rowStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1px;
    width: 100%;

    .coloredDivStyle {
      width: 100%;
      min-height: 20px;
      margin-left: 5px;
      background-color: #af9279;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 10px;
      color: #000;
      // font-family: "Luckiest Guy", "cursive"; //header
      font-family: "Lilita One", "cursive"; //regular
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      .hint-text{
        margin-bottom: 0px;
      }
    }
  }

  .text {
    text-align: left;
    color: #101010;
    font-family: "Luckiest Guy", "cursive"; //header
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}

.guess-word {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .box {
    padding: 15px 50px;
    border-radius: 20px;
    background: #fff;
    text-align: center;

    .guess-word-text {
      color: #101010;
      font-family: "Luckiest Guy", "cursive"; //header
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 0px;
    }
  }
}

.guess-header-text {
  display: flex;
  text-align: center;
  color: #ffffff;
  font-family: "Luckiest Guy", "cursive"; //header
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.guess-word-text {
  color: #fff;
  font-family: "Lilita One", "cursive"; //regular
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.inner-box {
  display: flex;
  width: 300px;
  height: 35px;
  margin: 10px;
  padding: 0 16px 0 16px;
  border-bottom: 1px solid #dbd8d8;
  align-items: center;
  justify-content: space-between;

  .guess-word-text {
    width: 100%;
    color: #fff;
    font-family: "Lilita One", "cursive"; //regular
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .img-box {
    width: 20px;
    height: 20px;
    background-size: cover;

    &.correct {
      background-image: url("../../Assets/Images/correct.png");
    }

    &.wrong {
      background-image: url("../../Assets/Images/wrong.png");
    }
  }
}

.textbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;

  input[type="text"] {
    padding: 15px 10px;
    width: 97%;
    text-align: center;
    border: none;
    outline: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.6);
    color: #101010;
    font-family: "Lilita One", "cursive"; //regular
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    transition: background 0.3s, color 0.3s;
  }::placeholder {
    color: rgba(16, 16, 16, 0.8);
  }

  input[type="text"]:focus {
    background: rgba(255, 255, 255, 0.8);
    color: #000;  
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  input:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.4s ease;
}
}

.button-raw {
  display: flex;
  justify-content: flex-end;

  .result {
    justify-content: center;
  }

  .button {
    margin-right: 8px;
    padding: 10px 20px;
    color: #fff;
    // font-family: "Luckiest Guy", "cursive";
    font-family: "Lilita One", "cursive"; //regular
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-variant: small-caps;
    text-align: center;
    cursor: pointer;
  }

  .primary-btn {
    border: none;
    border-radius: 12px;
    background: linear-gradient(238deg, #7f30ff 0%, #ff4880 100%);
  }

  .skip {
    border-radius: 12px;
    border: 2px solid #b59494;
    background: rgba(255, 255, 255, 0.1);
  }
}

//mobile
@media screen and (max-width: 480px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
    // height: auto;
    height: 65vh;
  }

  .center-container {
    background: transparent;
  }

  // .inner-container {
  //   width: calc(100% - 150px);
  // }

  .side-col,
  .side-col-nav,
  .side-col-girl {
    width: 100%;
  }

  .left-container {
    flex-direction: column;
    align-items: center;
  }

  .right-container {
    align-items: center;
  }

  .center-box {
    top: 10px;
  }

  .guess-word .box {
    width: 90%;
  }

  .textbox-container .textbox input[type="text"] {
    width: 90%;
  }
}




// code by shasheesha - start -----

.global-cover {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
}

.navigation-options {
  display: flex;
  align-items: center;
  height: fit-content;
  position: relative;
  padding: 40px 0 0 0;
  height: calc(100% - 40px);

  .btn-area {
    display: flex;
    gap: 5px;
  }
}

.container {
  justify-content: center;

  .center-box {
    max-width: 40vw;
    min-width: 300px;
    width: 100%;
    padding: 0 40px;
    z-index: 2;

    .box {
      width: 100%;
      padding: 15px 0px
    }
  }
}


// .inner-container{
//   width: 100%;
// }

// .right-container{
//   padding: 0px 30px 0px 0px;
// }

.anim-girl {
  position: absolute;
  width: 20px;
  height: 100%;
  z-index: 2;
  top: 0px;
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .div-girl {
    height: 70%;
    // right: 0px;
    // transform: translate3d(0, -50%, 0);
    // bottom: -50%;
  }
}

.gusses-sec {
  z-index: 2;
  right: 0px;
  width: fit-content;
  top: 0px;
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;

  .gusses-header {
    display: none;
  }

  .guesses-box-list {

    .inner-box {
      width: 200px;
      height: auto;
    }
  }

}


@media screen and (max-width: 992px) {
  .container .center-box {
    max-width: 75vw;

    .box {
      width: auto;
    }
  }

  .gusses-sec {
    width: 100vw;
  }

  .anim-girl {
    display: none;
  }

  .navigation-options {
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px 0 10px;
    height: fit-content;

    .btn-area {

      .div-btn {
        padding: 5px 10px;
        height: 100%;
        left: inherit;
        min-width: 100%;
        width: 30%;
        display: flex;
        justify-content: space-around;
      }

      .lable {
        font-size: 14px;
      }

      .icon {
        width: 20px;
        height: auto;
        margin-left: 10px;
        margin-right: 0px;
      }
    }
  }

  .gusses-sec {
    z-index: 0;
    position: absolute;
    left: 100vw;
    top: 0px;
    width: 99.6vw;
    height: 99.6vh;
    display: contents;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    border-radius: 5px;
    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    visibility: hidden;
    padding: 0px;

    .gusses-header {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-right: 50px;
      position: absolute;
      top: 20px;
    }

    .img-close {
      cursor: pointer;
    }
  }

  .gusses-sec-active {
    left: 0vw;
    z-index: 3;
    transition: 0.5s;
    visibility: visible;
    display: flex;
  }

  .container {
    // height: 70vh;
  }

  .inner-container {
    width: 100%;
  }

  .guess-header-text {
    font-size: 20px;
  }

  .guess-word .box .guess-word-text {
    font-size: 20px;
  }

  .textbox-container input[type=text] {
    font-size: 20px;
  }

  .button-raw {
    justify-content: center;
    // flex-direction: column;

    .button {
      width: 100%;
      margin: 0px;
      padding: 10px 15px;
    }

    .button+.button {
      margin-left: 10px;
    }
  }


  .body::before {
    bottom: 330px;
  }

  .gusses-sec {
    .guesses-box-list {
      width: 80vw;

      .inner-box {
        height: auto;
        width: auto;

        .guess-word-text {
          width: 100%;
        }
      }
    }
  }
}

// code by shasheesha - end -----