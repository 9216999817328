.landscape-block {
  display: none;
}

.body {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  background-image: url("../src/Assets/Images/DBFD_backgroun_jpg.jpg");  
}

.background-animation {
  content: "";
  position: fixed;
  left: -360px;
  bottom: -651px;
  width: 3321px;
  height: 3060px;
  background-image: url("../src/Assets/Images/BG_Swirl_min.png");
  background-position: center;
  background-size: cover;
  animation: rotation  120s linear infinite;
  z-index: 0;
}


@keyframes rotation {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}



.confettis {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.confetti {
  left: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  transform-origin: left top;
  animation: confetti 5s ease-in-out -2s infinite;
}
@keyframes confetti {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }
  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }
  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
  }
  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
  }
  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
  }
}

.confetti:nth-child(1) {
  left: 10%;
  animation-delay: 0;
  background-color: #fc0120;
}
.confetti:nth-child(2) {
  left: 20%;
  animation-delay: -5s;
  background-color: #8257e6;
}
.confetti:nth-child(3) {
  left: 30%;
  animation-delay: -3s;
  background-color: #ffbf4d;
}
.confetti:nth-child(4) {
  left: 40%;
  animation-delay: -2.5s;
  background-color: #fe5d7a;
}
.confetti:nth-child(5) {
  left: 50%;
  animation-delay: -4s;
  background-color: #45ec9c;
}
.confetti:nth-child(6) {
  left: 60%;
  animation-delay: -6s;
  background-color: #f6e327;
}
.confetti:nth-child(7) {
  left: 70%;
  animation-delay: -1.5s;
  background-color: #f769ce;
}
.confetti:nth-child(8) {
  left: 80%;
  animation-delay: -2s;
  background-color: #007de7;
}
.confetti:nth-child(9) {
  left: 90%;
  animation-delay: -3.5s;
  background-color: #63b4fc;
}
.confetti:nth-child(10) {
  left: 100%;
  animation-delay: -2.5s;
  background-color: #f9c4ea;
}

.main {
  flex: 1;
}

.nav button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
}

.content {
  padding: 20px;
  border: 1px solid #ddd;
}

.footer {
   height: auto;
   z-index: 2;

  .div-banner {
    display: flex;
    height:100%;
    width: auto;
    justify-content: center;
    cursor: pointer;

    img{
      width: inherit;
      height: fit-content;
    }
  }

  .cookie {
    background-color: #000000;
    position: fixed;
    bottom: 1%;
    border-radius: 20px;
    right: 1%;
    z-index: 10;
    width: 25%;
  }

  .cookie .txt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    float: left;
    width: 100%;
  }
  .txt p {
    color: #fff;
    font-family: "Lilita One", "cursive"; //regular
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-variant: small-caps;
  }

  .button {
    margin-left: 10px;
    padding: 10px 20px;
    color: #fff;
    font-family: "Lilita One", "cursive"; //regular
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-variant: small-caps;
    cursor: pointer;
  }

  .primary-btn {
    border: none;
    border-radius: 12px;
    background: linear-gradient(238deg, #7f30ff 0%, #ff4880 100%);
  }

  .wishlist-text {
    display: flex;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .label {
      color: #ffffff;
      font-family: "Lilita One", "cursive"; //regular
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .wishlist-text :hover {
    font-size: 18px;
    transition: 0.4s;
    cursor: pointer;
  }

  .div-social {
    width: 330px;
    height: 20px;
    display: flex;
    justify-content: center;

    .link-social {
      color: #ffffff;
      margin-left: 20px;
      font-size: 18px;
    }

    .link-social :hover {
      font-size: 22px;
      transition: 0.1s;
    }

    .SnapLogo {
      margin-left: 10px;
      height: 100%;
    }

    .SnapLogo :hover {
      height: 120%;
      transition: 0.1s;
    }
  }
}
@media screen and (max-width: 992px){
  .footer{
    // margin-top: 15px;
    margin-bottom: 60px;
  }
  .footer .div-banner {
    width: 100vw;
  }
}

@media screen and (max-width: 480px) {
  .footer .cookie {
    background-color: #000000;
    position: fixed;
    bottom: 70px;
    border-radius: 20px;
    right: 1%;
    left: 1%;
    z-index: 10;
    width: 98%;
  }

  .background-animation {
    content: "";
    position: fixed;
    left: -230px;
    bottom: -300px;
    width: 1000px;
    height: 1500px;
    background-image: url("../src/Assets/Images/BG_Swirl_min.png");
    background-position: center;
    background-size: cover;
    animation: rotation  120s linear infinite;
    z-index: 0;
  }
}

@media screen and (max-width: 992px) and (orientation: landscape){
  .body{
    display: none;
  }
  .landscape-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); 
    color: #fff; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .landscape-block p {
    font-size: 20px;
    text-align: center;
  }
}