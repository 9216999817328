.container {
  align-items: center;
  .header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    .h2 {
      color: #fff;
      font-weight: 200;
      text-align: center;
      font-family: "Luckiest Guy", "cursive";//header
    }
  }
.center-container-result{
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  //margin: 5px 0px;
  z-index: 1;
}
  .guess-word-result {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 20px;

    .box {
      padding: 15px 50px;
      border-radius: 20px;
      background: #fff;
      //width: 400px;
      text-align: center;

      .guess-word-text {
        color: #101010;
        font-family: "Luckiest Guy", "cursive";//header
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  .box-list-text {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px 10px 20px;
    align-items: center;
    font-family:  "Lilita One", "cursive";//regular
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    color: #fff;
  }
  .guesses-box-row {
    display: flex;
    justify-content: space-around;
    margin: 10px;

    .inner-box {
      display: flex;
      width: 250px;
      height: 35px;
      border-bottom: 1px solid #dbd8d8;
      align-items: center;
      justify-content: space-between;

      .img-box {
        width: 20px;
        height: 20px;
        background-size: cover;

        &.correct {
          background-image: url("../../Assets/Images/correct.png");
        }

        &.wrong {
          background-image: url("../../Assets/Images/wrong.png");
        }
      }
    }
  }

  .color-box {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 16px;
    .box {
      width: 25px;
      border-radius: 5px;
      background: white;
      margin-right: 5px;
      border: 1px solid black;

      &.correct {
        background: #90ee90 !important;
      }

      &.wrong {
        background: #F08080 !important;
      }

      &.skip {
        background: gray !important;
      }
    }
  }
}
